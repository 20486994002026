@font-face {
    font-family: 'geomanistregular';
    src: url('../../public/fonts/Geomanist/Geomanist-Regular/geomanist-regular-webfont.eot');
    src: url('../../public/fonts/Geomanist/Geomanist-Regular/geomanist-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/Geomanist/Geomanist-Regular/geomanist-regular-webfont.woff2') format('woff2'),
    url('../../public/fonts/Geomanist/Geomanist-Regular/geomanist-regular-webfont.woff') format('woff'),
    url('../../public/fonts/Geomanist/Geomanist-Regular/geomanist-regular-webfont.ttf') format('truetype'),
    url('../../public/fonts/Geomanist/Geomanist-Regular/geomanist-regular-webfont.svg#geomanistregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'geomanistregular';
    src: url('../../public/fonts/Geomanist/Geomanist-Medium/geomanist-medium-webfont.eot');
    src: url('../../public/fonts/Geomanist/Geomanist-Medium/geomanist-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/Geomanist/Geomanist-Medium/geomanist-medium-webfont.woff2') format('woff2'),
    url('../../public/fonts/Geomanist/Geomanist-Medium/geomanist-medium-webfont.woff') format('woff'),
    url('../../public/fonts/Geomanist/Geomanist-Medium/geomanist-medium-webfont.ttf') format('truetype'),
    url('../../public/fonts/Geomanist/Geomanist-Medium/geomanist-medium-webfont.svg#geomanistmedium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'geomanistregular';
    src: url('../../public/fonts/Geomanist/Geomanist-Bold/geomanist-bold-webfont.eot');
    src: url('../../public/fonts/Geomanist/Geomanist-Bold/geomanist-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/Geomanist/Geomanist-Bold/geomanist-bold-webfont.woff2') format('woff2'),
    url('../../public/fonts/Geomanist/Geomanist-Bold/geomanist-bold-webfont.woff') format('woff'),
    url('../../public/fonts/Geomanist/Geomanist-Bold/geomanist-bold-webfont.ttf') format('truetype'),
    url('../../public/fonts/Geomanist/Geomanist-Bold/geomanist-bold-webfont.svg#geomanistbold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'geomanistregular';
    src: url('../../public/fonts/Geomanist/Geomanist-Light/geomanist-light-webfont.eot');
    src: url('../../public/fonts/Geomanist/Geomanist-Light/geomanist-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/Geomanist/Geomanist-Light/geomanist-light-webfont.woff2') format('woff2'),
    url('../../public/fonts/Geomanist/Geomanist-Light/geomanist-light-webfont.woff') format('woff'),
    url('../../public/fonts/Geomanist/Geomanist-Light/geomanist-light-webfont.ttf') format('truetype'),
    url('../../public/fonts/Geomanist/Geomanist-Light/geomanist-light-webfont.svg#geomanistlight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'recoletaregular';
    src: url('../../public/fonts/Recoleta/Recoleta-Medium/Recoleta-Medium.eot');
    src: url('../../public/fonts/Recoleta/Recoleta-Medium/Recoleta-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/Recoleta/Recoleta-Medium/Recoleta-Medium.woff2') format('woff2'),
    url('../../public/fonts/Recoleta/Recoleta-Medium/Recoleta-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'recoletaregular';
    src: url('../../public/fonts/Recoleta/Recoleta-Bold/Recoleta-Bold.eot');
    src: url('../../public/fonts/Recoleta/Recoleta-Bold/Recoleta-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/Recoleta/Recoleta-Bold/Recoleta-Bold.woff2') format('woff2'),
    url('../../public/fonts/Recoleta/Recoleta-Bold/Recoleta-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
