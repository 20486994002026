@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'recoletaregular', Arial;
  font-weight: 500;
}

body {
  font-family: 'geomanistregular', Arial;
  overflow-x: hidden;
  color: #292B29;
  background-color: #fdfbf9;
}

.corp-form-submit-start {
  align-self: start;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: initial;
}

/* fix to avoid overlapping header after anchor link click */
:target::before {
  content: '';
  display: block;
  height: 100px;
  margin: -100px 0 0;
}

[hidden] {
  display: none;
}

.swiper-pagination-bullet {
  width: 14px !important;
  height: 14px !important;
  background-color: #292B29 !important;
  opacity: 0.6 !important;
}

.swiper-pagination-bullet-active {
  background-color: #292B29 !important;
  opacity: 1 !important;
}

@media only percy {
  .hide-in-percy {
    visibility: hidden;
  }
}

.th-corporate {
  /* Headline */
  /* Headline-XL: 42px / 48px */
  --font-size-headline-xl: 2.625rem;
  --line-height-headline-xl: 3rem;
  --letter-spacing-headline-xl: 0rem;
  /* Headline-L: 32px / 38px */
  --font-size-headline-l: 2rem;
  --line-height-headline-l: 2.375rem;
  --letter-spacing-headline-l: 0.04rem;
  /* Headline-M: 24px / 32px */
  --font-size-headline-m: 1.5rem;
  --line-height-headline-m: 2rem;
  --letter-spacing-headline-m: 0.045rem;
  /* Headline-S: 20px / 28px */
  --font-size-headline-s: 1.25rem;
  --line-height-headline-s: 1.75rem;
  --letter-spacing-headline-s: 0.0375rem;
  /* Headline-XS: 18px / 24px */
  --font-size-headline-xs: 1.125rem;
  --line-height-headline-xs: 1.5;
  --letter-spacing-headline-xs: 0.03375rem;
  /* Headline-XXS: 16px / 24px */
  --font-size-headline-xxs: 1rem;
  --line-height-headline-xxs: 1.5rem;
  --letter-spacing-headline-xxs: 1.5rem;
  /* Headline-XXXS: 16px / 24px */
  --font-size-headline-xxxs: 1rem;
  --line-height-headline-xxxs: 1.5rem;
  --letter-spacing-headline-xxxs: 0.03rem;
  /* Typography: Body */
  /* Body-XXL: 16 / 26px */
  --font-size-body-xxl: 1rem;
  --line-height-body-xxl: 1.625rem;
  --letter-spacing-body-xxl: 0.04rem;
  /* Body-XL: 16 / 26px */
  --font-size-body-xl: 1rem;
  --line-height-body-xl: 1.625rem;
  --letter-spacing-body-xl: 0.04rem;
  /* Body-L: 16 / 26px */
  --font-size-body-l: 1rem;
  --line-height-body-l: 1.625rem;
  --letter-spacing-body-l: 0.04rem;
  /* Body-M: 14px / 24px */
  --font-size-body-m: 0.875rem;
  --line-height-body-m: 1.5rem;
  --letter-spacing-body-m: 0.0175rem;
  /* Body-S: 12px / 22px */
  --font-size-body-s: 0.75rem;
  --line-height-body-s: 1.375rem;
  --letter-spacing-body-s: 0.015rem;
  /* Typography: Lead */
  /* Lead-M: 12px / 16px */
  --font-size-lead-m: 0.75rem;
  --line-height-lead-m: 1rem;
  --letter-spacing-lead-m: 0.1125rem;
  /* Lead-S: 10px / 16px */
  --font-size-lead-s: 0.625rem;
  --line-height-lead-s: 1rem;
  --letter-spacing-lead-s: 0.09375rem;
}

@media (min-width: 768px) {
  .th-corporate {
    /* Typography */
    /* Typography: Headline */
    /* Headline-XL: 64px / 70px */
    --font-size-headline-xl: 4rem;
    --line-height-headline-xl: 4.375rem;
    --letter-spacing-headline-xl: 0.12rem;
    /* Headline-L: 48px / 60px */
    --font-size-headline-l: 3rem;
    --line-height-headline-l: 3.75rem;
    --letter-spacing-headline-l: 0rem;
    /* Headline-M: 42px / 48px */
    --font-size-headline-m: 2.625rem;
    --line-height-headline-m: 3rem;
    --letter-spacing-headline-m: 0rem;
    /* Headline-S: 32px / 38px */
    --font-size-headline-s: 2rem;
    --line-height-headline-s: 2.375rem;
    --letter-spacing-headline-s: 0.04rem;
    /* Headline-XS: 24px / 32px */
    --font-size-headline-xs: 1.5rem;
    --line-height-headline-xs: 2rem;
    --letter-spacing-headline-xs: 0.045rem;
    /* Headline-XXS: 20px / 28px */
    --font-size-headline-xxs: 1.25rem;
    --line-height-headline-xxs: 1.75rem;
    --letter-spacing-headline-xxs: 0.0375rem;
    /* Headline-XXXS: 18px / 24px */
    --font-size-headline-xxxs: 1.125rem;
    --line-height-headline-xxxs: 1.5rem;
    --letter-spacing-headline-xxxs: 0.03375rem;
    /* Typography: Body */
    /* Body-XXL: 28px / 42px */
    --font-size-body-xxl: 1.75rem;
    --line-height-body-xxl: 2.625rem;
    --letter-spacing-body-xxl: 0rem;
    /* Body-XL: 24px / 36px */
    --font-size-body-xl: 1.5rem;
    --line-height-body-xl: 2.25rem;
    --letter-spacing-body-xl: 0.03rem;
    /* Body-L: 20px / 30px */
    --font-size-body-l: 1.25rem;
    --line-height-body-l: 1.875rem;
    --letter-spacing-body-l: 0.025rem;
    /* Body-M: 16px / 26px */
    --font-size-body-m: 1rem;
    --line-height-body-m: 1.625rem;
    --letter-spacing-body-m: 0.04rem;
    /* Body-S: 14px / 24px */
    --font-size-body-s: 0.875rem;
    --line-height-body-s: 1.5rem;
    --letter-spacing-body-s: 0.0175rem;
    /* Typography: Lead */
    /* Lead-M: 14px / 18px */
    --font-size-lead-m: 0.875rem;
    --line-height-lead-m: 1.125rem;
    --letter-spacing-lead-m: 0.13125rem;
    /* Lead-S: 12px / 16px */
    --font-size-lead-s: 0.75rem;
    --line-height-lead-s: 1rem;
    --letter-spacing-lead-s: 0.1125rem;
  }
}