.curve-mask-top,
.curve-mask-top-product-banner {
  clip-path: url(#curve-mask-top-mobile);
}

.curve-mask-bottom,
.curve-mask-bottom-product-banner {
  clip-path: url(#curve-mask-bottom-mobile);
}

.curve-mask-inverted {
  clip-path: url(#curve-mask-mobile-inverted);
}

.h-bannerShort .curve-mask-bottom,
.h-bannerShort .curve-mask-bottom-product-banner {
  height: 92%;
}

@media (min-width: 480px) {
  .curve-mask-top,
  .curve-mask-top-product-banner {
    clip-path: url(#curve-mask-top-tablet);
  }

  .curve-mask-bottom,
  .curve-mask-bottom-product-banner {
    clip-path: url(#curve-mask-bottom-tablet);
  }
}

@media (min-width: 768px) {
  .curve-mask-top-product-banner {
    clip-path: url(#curve-mask-top-tab);
  }

  .curve-mask-bottom-product-banner {
    clip-path: url(#curve-mask-bottom-tab);
  }

  .curve-mask-top {
    clip-path: url(#curve-mask-top-desktop);
  }

  .curve-mask-bottom {
    clip-path: url(#curve-mask-bottom-desktop);
  }
}

@media (min-width: 1024px) {
  .curve-mask-top,
  .curve-mask-top-product-banner {
    clip-path: url(#curve-mask-top-desktop);
  }

  .curve-mask-bottom,
  .curve-mask-bottom-product-banner {
    clip-path: url(#curve-mask-bottom-desktop);
  }

  .curve-mask-inverted {
    clip-path: url(#curve-mask-desktop-inverted);
  }
}
